import { sleepMs } from '@/helpers/sleepMs'

export const withExponentialBackoff = <T, X>(
  fn: (arg: T) => X | Promise<X>,
  maxAttempts = 5,
  attempt = 0,
) => async (x: T) => {
    try {
      const result = await fn(x)
      return result
    } catch (e) {
      if ((attempt + 1) < maxAttempts) {
        await sleepMs(2 ** attempt * 1000)
        const retry: X = await withExponentialBackoff(fn, maxAttempts, attempt + 1)(x)
        return retry
      }
      throw e
    }
  }

export default withExponentialBackoff
