import { useEffect, useState } from 'react'
import type { NextPageContext } from 'next'
import { getCustomer, type GraphQlCustomer } from '@/helpers/graphql'
import type { SitewideBanner } from '@/types/Contentful/SitewideBanner'
import type { IMeganav } from '@/types/Meganav'
import type { StandardCart } from '@/types/ShopFront/CheckoutStandards'

import Axios from 'axios'
import { CDN_API } from '@/services/Configuration'
import { error, log } from '@/services/Log'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { getRefererString } from '@/helpers/getRefererString'
import { type BloomreachConfig } from '@/helpers/getBloomreachConfig'
import { withExponentialBackoff } from '@/helpers/withExponentialBackoff'
import type { EventBannerType } from '@/types/Contentful/EventBanner'
import type { FooterDeploymentType } from '@/types/Contentful/Footer'
import { timePromiseAndLog } from '@/helpers/timePromiseAndLog'
import { isFeatureEnabled } from '@/helpers/isFeatureEnabled'

export interface SiteWideSSRProps {
  sitewideBanner: SitewideBanner | null,
  megaNav: IMeganav | null
  referer?: string
  bloomreachConfig?: BloomreachConfig
  sort?: string
  eventBanner: EventBannerType | null
  footer: FooterDeploymentType | null
}

const clientCachedMeganav = null
const getMeganavWithRetry = withExponentialBackoff(() => Axios.get<IMeganav>(`${CDN_API}/ssr-api/get-meganav`))
const getMegaNav = async () => {
  if (clientCachedMeganav) {
    return clientCachedMeganav
  }
  try {
    const { data: meganav } = await getMeganavWithRetry(undefined)
    return meganav
  } catch (err) {
    error(`Failed to retrieve Meganav ${String(err)}`)
  }
  return null
}

const cartGetter = async () => allPromisesWithRetries(() => [import('@/services/Cart/getCart')]).then(([{ getCart }]) => getCart())

export const useClientSideCartAndUser = () => {
  const [hasMounted, setHasMounted] = useState<boolean>(false)
  const [cart, setCart] = useState<StandardCart | null>(null)
  const [user, setUser] = useState<GraphQlCustomer | null>(null)
  const [userLoaded, setUserLoaded] = useState<boolean>(false)

  useEffect(() => setHasMounted(true), [])
  useEffect(() => {
    const loadUser = async () => {
      try {
        const userRequest = await timePromiseAndLog('useClientSideCartAndUser.loadUser')(
          getCustomer(),
        )
        const newUser = userRequest.success ? userRequest.customer : null
        setUser(newUser)
      } catch (err) {
        error(`Failed to load user ${String(err)}`)
      } finally {
        setUserLoaded(true)
      }
    }

    if (hasMounted) {
      loadUser()
    }
  }, [hasMounted])
  useEffect(() => {
    allPromisesWithRetries(() => [import('@/services/Facebook')])
      .then(([{ default: Facebook }]) => Facebook.init())
      .catch((err) => error(`Failed to initialize Facebook Pixel ${String(err)}`))
  }, [])

  useEffect(() => {
    const loadCart = async () => {
      try {
        const newCart = await timePromiseAndLog('useClientSideCartAndUser.loadCart')(cartGetter())
        setCart(newCart)
      } catch (err) {
        error(`Failed to load cart ${String(err)}`)
      }
    }
    if (hasMounted && userLoaded) {
      loadCart()
    }
  }, [hasMounted, userLoaded])

  const reloadCustomer = async () => {
    const userRequest = await timePromiseAndLog('useClientSideCartAndUser.reloadCustomer')(getCustomer())
    const newUser = userRequest.success ? userRequest.customer : null
    setUser(newUser)
  }
  return {
    cart,
    setCart,
    user,
    setUser,
    reloadCustomer,
    userLoaded,
  }
}

export const SiteWideInitialPropsFailure: SiteWideSSRProps = {
  sitewideBanner: null,
  megaNav: null,
  referer: '',
  eventBanner: null,
  footer: null,
}

type GetSiteWideInitialPropsHandler = (
  ctx: Pick<NextPageContext, 'asPath' | 'query'> & {
    req?: {
      headers?: Record<string, string | string[] | undefined>
    }
    res?: {
      setHeader?: (name: string, value: string) => void
    }
  },
  providedUseOpenSearch?: boolean,
) => Promise<SiteWideSSRProps>

const takeStringfromQueryParam = (param: undefined | string[] | string) => {
  if (param === undefined) {
    return undefined
  }
  return (
    Array.isArray(param) ? param.join(',') : param
  )
}

export const getSiteWideInitialProps: GetSiteWideInitialPropsHandler = async (
  ctx,
  providedUseOpenSearch,
) => {
  const {
    res,
    asPath,
    req,
    query,
  } = ctx
  const [
    { getSiteWideBanner },
    { getEventBanner, getFooterFromContentful },
    { getBloomreachConfig },
  ] = await allPromisesWithRetries(() => [
    import('@/services/getSiteWideBanner'),
    import('@/services/Content'),
    import('@/helpers/getBloomreachConfig'),
  ])
  try {
    log(`getSiteWideInitialProps asPath: ${String(asPath)}`)
    if (
      isFeatureEnabled('isOpenSearchABEnabled')
      && (
        asPath?.includes('/search')
        || asPath?.includes('/c-')
        || asPath?.includes('/b-')
      )
    ) {
      res?.setHeader?.('cache-control', 'public, must-revalidate, proxy-revalidate, max-age=0, s-maxage=0')
    } else {
      res?.setHeader?.(
        'cache-control',
        'public, s-maxage=900',
      )
    }
    const referer = getRefererString(String(req?.headers?.referer || ''))
    const bloomreachConfig = getBloomreachConfig(ctx, providedUseOpenSearch)
    const [
      sitewideBanner,
      megaNav,
      eventBanner,
      footer,
    ] = await Promise.all([
      timePromiseAndLog('services/getSiteWideInitialProps.getSiteWideBanner')(getSiteWideBanner()),
      timePromiseAndLog('services/getSiteWideInitialProps.getMegaNav')(getMegaNav()),
      timePromiseAndLog('services/getSiteWideInitialProps.getEventBanner')(getEventBanner()),
      timePromiseAndLog('services/getSiteWideInitialProps.getFooterFromContentful')(getFooterFromContentful()),
    ])
    return {
      sitewideBanner,
      megaNav,
      eventBanner,
      ...(referer && { referer }),
      bloomreachConfig,
      footer,
      sort: takeStringfromQueryParam(query?.sort),
    }
  } catch (err) {
    error(`getSiteWideInitialProps Failed ${String(err)}`)
  }
  return SiteWideInitialPropsFailure
}

export default getSiteWideInitialProps
